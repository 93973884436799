var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "min-height": "449px",
      "margin-bottom": "-7px"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.content,
      expression: "content",
      modifiers: {
        "lazy": true
      }
    }],
    ref: "editor",
    staticStyle: {
      "width": "100%",
      "display": "none"
    },
    attrs: {
      "id": _vm.$data._id,
      "name": _vm.name,
      "rows": _vm.rows,
      "cols": "cols"
    },
    domProps: {
      "value": _vm.content
    },
    on: {
      "change": [function ($event) {
        _vm.content = $event.target.value;
      }, _vm.input]
    }
  }, [_vm._v(_vm._s(_vm.value))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }